<template>
  <div class="cabeceras">

    <!--CALENDARIO-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Calendario</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/calendarios" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/calendarios</a>
        </p>

        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg2">Imagen</label>
            <b-form-file v-model="file1" id="inputImg2" type="file" name="inputImg2" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg3">Imagen Movil</label>
            <b-form-file v-model="file1mv" id="inputImg3" type="file" name="inputImg3" :placeholder="'Subir imagen'" />
          </div>
        </div>

        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file1, file1mv, 'Calendario')" v-if="caldata == 'No se encontraron coincidencias'">{{
              subimg == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(caldata.id, file1, file1mv, 'Calendario')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="caldata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + caldata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + caldata.image_mv" alt="alt" class="bannee">
        <div class="row mx-0 row-one">
        </div>

        <div>
        </div>

        <div class="div-error" v-if="status == 'error' && secc == 'Calendario'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--EQUIPOS-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Equipo</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/jugadores" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/jugadores</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg4">Imagen</label>
            <b-form-file v-model="file2" id="inputImg4" type="file" name="inputImg4" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg5">Imagen Movil</label>
            <b-form-file v-model="file2mv" id="inputImg5" type="file" name="inputImg5" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file2, file2mv, 'Equipo')" v-if="eqdata == 'No se encontraron coincidencias'">{{ subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(eqdata.id, file2, file2mv, 'Equipo')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="eqdata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + eqdata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + eqdata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Equipo'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--NOTICIAS-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Noticias</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/blog/expansion" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/blog/expansion</a>
        </p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/blog/fuerzas-basicas" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/blog/fuerzas-basicas</a>
        </p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/blog/eventos" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/blog/eventos</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg6">Imagen</label>
            <b-form-file v-model="file3" id="inputImg6" type="file" name="inputImg6" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg7">Imagen Movil</label>
            <b-form-file v-model="file3mv" id="inputImg7" type="file" name="inputImg7" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file3, file3mv, 'Noticias')" v-if="nodata == 'No se encontraron coincidencias'">{{
              subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(nodata.id, file3, file3mv, 'Noticias')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="nodata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + nodata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + nodata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Noticias'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--GALERÍA-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Galería</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/galeria" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/galeria</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg8">Imagen</label>
            <b-form-file v-model="file4" id="inputImg8" type="file" name="inputImg8" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg9">Imagen Movil</label>
            <b-form-file v-model="file4mv" id="inputImg9" type="file" name="inputImg9" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file4, file4mv, 'Galeria')" v-if="galdata == 'No se encontraron coincidencias'">{{
              subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(galdata.id, file4, file4mv, 'Galeria')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="galdata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + galdata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + galdata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Galeria'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--VIDEOS-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Videos</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/videos" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/videos</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg10">Imagen</label>
            <b-form-file v-model="file5" id="inputImg10" type="file" name="inputImg10" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg11">Imagen Movil</label>
            <b-form-file v-model="file5mv" id="inputImg11" type="file" name="inputImg11" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file5, file5mv, 'Videos')" v-if="vidata == 'No se encontraron coincidencias'">{{ subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(vidata.id, file5, file5mv, 'Videos')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="vidata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + vidata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + vidata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Videos'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--FILOSOFÍA-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Filosofía</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/filosofia" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/filosofia</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg12">Imagen</label>
            <b-form-file v-model="file6" id="inputImg12" type="file" name="inputImg12" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg13">Imagen Movil</label>
            <b-form-file v-model="file6mv" id="inputImg13" type="file" name="inputImg13" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file6, file6mv, 'Filosofia')" v-if="fidata == 'No se encontraron coincidencias'">{{
              subimg == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(fidata.id, file6, file6mv, 'Filosofia')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="fidata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + fidata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + fidata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Filosofia'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--ESTADIO-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Estadio</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/estadio" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/estadio</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg14">Imagen</label>
            <b-form-file v-model="file7" id="inputImg14" type="file" name="inputImg14" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg15">Imagen Movil</label>
            <b-form-file v-model="file7mv" id="inputImg15" type="file" name="inputImg15" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file7, file7mv, 'Estadio')" v-if="estdata == 'No se encontraron coincidencias'">{{
              subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(estdata.id, file7, file7mv, 'Estadio')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="estdata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + estdata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + estdata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Estadio'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--BOLETOS-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Boletos</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/boletos" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/boletos</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg16">Imagen</label>
            <b-form-file v-model="file8" id="inputImg16" type="file" name="inputImg16" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg17">Imagen Movil</label>
            <b-form-file v-model="file8mv" id="inputImg17" type="file" name="inputImg17" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file8, file8mv, 'Boletos')" v-if="boldata == 'No se encontraron coincidencias'">{{
              subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(boldata.id, file8, file8mv, 'Boletos')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="boldata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + boldata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + boldata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Boletos'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <!--TIENDA-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Tienda</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/tienda" target="_blank"
            rel="noopener noreferrer">https://cimarronesfc.mx/tienda</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg18">Imagen</label>
            <b-form-file v-model="file9" id="inputImg18" type="file" name="inputImg18" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg19">Imagen Movil</label>
            <b-form-file v-model="file9mv" id="inputImg19" type="file" name="inputImg19" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file9, file9mv, 'Tienda')" v-if="tiedata == 'No se encontraron coincidencias'">{{ subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(tiedata.id, file9, file9mv, 'Tienda')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="tiedata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + tiedata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + tiedata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Tienda'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>


    <!--TIENDA-->
    <div id="stn-edituser" class="stn_edituserAdd mb23">
      <div class=" ">
        <p class="tacnt color-1 titulo123">Cabecera <b>Categoría Tienda</b></p>
        <p class="tacnt">
          <a class="link34" href="https://cimarronesfc.mx/categoria/jersey" target="_blank"
            rel="noopener noreferrer">EJ: https://cimarronesfc.mx/categoria/jersey</a>
        </p>
        <div class="row mx-0 row-two">
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg20">Imagen</label>
            <b-form-file v-model="file10" id="inputImg20" type="file" name="inputImg20" :placeholder="'Subir imagen'" />
          </div>
          <div class="div-image3 ">
            <label class="labelImg2 color-1" for="inputImg21">Imagen Movil</label>
            <b-form-file v-model="file10mv" id="inputImg21" type="file" name="inputImg21" :placeholder="'Subir imagen'" />
          </div>
        </div>
        <div class="btn-form-2">
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="agregarImagen(file10, file10mv, 'CTienda')" v-if="ctidata == 'No se encontraron coincidencias'">{{ subimg
              == true ? 'SUBIENDO IMAGEN' : 'AGREGAR' }}</b-btn>
          <b-btn variant="outline-secondary" class="editar-modal dabuton" type="submit"
            @click="editarImagen(ctidata.id, file10, file10mv, 'CTienda')" v-else>{{ subimg == true ? 'SUBIENDO IMAGEN' :
              'CAMBIAR CABECERAS' }}</b-btn>
        </div>
      </div>
      <div v-if="ctidata !== 'No se encontraron coincidencias'">
        <p class="tacnt tcabc color-1">Cabecera <b>PC</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + ctidata.image" alt="alt" class="bannee">
        <p class="tacnt tcabc color-1">Cabecera <b>Movil</b></p>
        <img :src="urlpath.url() + '/get-cabeceras-image/' + ctidata.image_mv" alt="alt" class="bannee">
      </div>

      <div class="row mx-0 row-one">
        <div class="div-error" v-if="status == 'error' && secc == 'Tienda'">
          <p class="msg-error">{{ msg }}</p>
        </div>
      </div>
    </div>

    <b-modal id="modal-editar" ref="modal-editar" no-close-on-backdrop centered hide-footer hide-header>
      <div class="message-modal">
        <img :src="IconSuccess" class="success" /><br />
        <p class="text-modal color-6">Se agregó la cabecera correctamente</p>
      </div>
      <div class="">
        <b-button class="btn-modal" @click="agregarOtro()">ACEPTAR</b-button>
        <b-button type="button" aria-label="Close" class="close-modal" @click="salir()">×</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
let urlpath = require('../../global/url')
import IconSuccess from "../../assets/Vector.png";
export default {
  data() {
    return {
      urlpath,
      IconSuccess: IconSuccess,
      //CALENDARIO
      file1: null,
      file1mv: null,
      caldata: [],

      //EQUIPOS
      file2: null,
      file2mv: null,
      eqdata: [],

      //NOTICIAS
      file3: null,
      file3mv: null,
      nodata: [],

      //GALERÍA
      file4: null,
      file4mv: null,
      galdata: [],

      //VIDEOS
      file5: null,
      file5mv: null,
      vidata: [],

      //FILOSOFÍA
      file6: null,
      file6mv: null,
      fidata: [],

      //ESTADIO
      file7: null,
      file7mv: null,
      estdata: [],

      //BOLETOS
      file8: null,
      file8mv: null,
      boldata: [],

      //TIENDA
      file9: null,
      file9mv: null,
      tiedata: [],

//TIENDA CAT
file10: null,
file10mv: null,
ctidata: [],
      subimg: false,
      status: "",
      msg: "",
      secc: "",
    }
  },
  async created() {
    await this.getCabeceras('cabeceras');
    let payload2 = {
      id: 'Calendario',
      option: "cabeceras/section",
    };
    this.caldata = await this.getCabecerasById(payload2);
    let payload3 = {
      id: 'Equipo',
      option: "cabeceras/section",
    };
    this.eqdata = await this.getCabecerasById(payload3);
    let payload4 = {
      id: 'Noticias',
      option: "cabeceras/section",
    };
    this.nodata = await this.getCabecerasById(payload4);
    let payload5 = {
      id: 'Galeria',
      option: "cabeceras/section",
    };
    this.galdata = await this.getCabecerasById(payload5);
    let payload6 = {
      id: 'Videos',
      option: "cabeceras/section",
    };
    this.vidata = await this.getCabecerasById(payload6);
    let payload7 = {
      id: 'Filosofia',
      option: "cabeceras/section",
    };
    this.fidata = await this.getCabecerasById(payload7);
    let payload8 = {
      id: 'Estadio',
      option: "cabeceras/section",
    };
    this.estdata = await this.getCabecerasById(payload8);
    let payload9 = {
      id: 'Boletos',
      option: "cabeceras/section",
    };
    this.boldata = await this.getCabecerasById(payload9);
    let payload10 = {
      id: 'Tienda',
      option: "cabeceras/section",
    };
    this.tiedata = await this.getCabecerasById(payload10);
    let payload11 = {
      id: 'CTienda',
      option: "cabeceras/section",
    };
    this.ctidata = await this.getCabecerasById(payload11);
  },
  computed: {
    cabeceras() {
      return this.$store.getters["cabeceras/data"];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    ...mapActions("cabeceras", ["getCabecerasById"]),
    ...mapActions("cabeceras", ["getCabeceras"]),
    ...mapActions("cabeceras", ["setData"]),
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    agregarImagen: async function (image, image_mv, section) {
      let id_banner = this.$route.params.id;
      this.subimg = true;
      this.secc = section;

      if (image == null) {
        this.status = 'error'
        this.msg = 'Imagen no subida'
      } else {
        if (image.size > 10000000) {
          this.status = 'error'
          this.msg = 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
        } else {

          var data = new FormData();

          data.append('image', image);
          data.append('image_mv', image_mv);
          data.append('section', section);
          data.append('_method', 'POST');

          this.status = ''
          this.msg = ''

          let result = await this.$store.dispatch("main/addItem", { option: 'cabeceras', item: data });
          console.log(result)
          if (result.status == 'error') {
            this.status = 'error'
            this.msg = result.message
            this.subimg = false
          } else { // success 
            this.showModal()
            this.subimg = false
          }
        }
      }
    },
    editarImagen: async function (id, image, image_mv, section) {
      //let id_banner = this.$route.params.id; 
      this.subimg = true;
      this.secc = section;

      if (image !== null && image_mv !== null) {
        this.status = 'error'
        this.msg = 'No has seleccionado una imagen.'
        return;
      }
      if (image !== null) {
        if (image.size > 10000000) {
          this.status = 'error'
          this.msg = 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
          return;
        }
      }
      if (image_mv !== null) {
        if (image_mv.size > 10000000) {
          this.status = 'error'
          this.msg = 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
          return
        }
      }


      //else {

      var data = new FormData();

      data.append('id', id);
      data.append('image', image);
      data.append('image_mv', image_mv);
      data.append('section', section);
      data.append('_method', 'PUT');

      this.status = ''
      this.msg = ''

      let result = await this.$store.dispatch("main/editItem", { option: 'cabeceras', item: data });
      console.log(result)
      console.log('moo')

      if (result.status == 'error') {
        this.status = 'error'
        this.msg = result.message
        this.subimg = false
      } else { // success 
        this.showModal()
        this.subimg = false
        //} 
      }
    },
    showModal() {
      this.$refs['modal-editar'].show()
    },
    image: function (img) {
      let src = this.url + '/get-cabeceras-image/' + img;
      this.status_img(img)
      return src
    },
    status_img: async function (img) {
      let userimg = ''
      try {
        userimg = await this.$store.dispatch("main/getImage", { image: img, option: 'cabeceras' })
        if (userimg.data.status == 'error') {
          this.msgimg = 'error'
        } else {
          this.msgimg = 'success'
        }
      } catch (err) {
        this.msgimg = 'error'
      }
    },
    agregarOtro: function () {
      this.wait();
    },
  },
};
</script>
<style>
.btn-form-2 button.dabuton {
  display: flex;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-7) !important;
  width: 11.125vw;
  height: 3.444444vw;
  color: var(--color-4) !important;
  border-radius: 1vw !important;
  font-size: 0.702778vw !important;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
  justify-content: center;
  align-items: center;
}

.bannee {
  width: 60%;
  display: flex;
  margin: 0 auto;
  /*3vw */
}

.cabeceras .titulo123 {
  font-size: 2.05vw;
  margin-top: 3vw;
}

.cabeceras .tcabc {
  margin: 2.5vw auto 0.73vw;
}

.mb23 {
  padding-bottom: 4vw;
}

a.link34 {
  margin: auto;
  text-align: center;
  font-size: .93vw;
  text-decoration: none;
}</style>
