<template>
  <div class="section_content">
    <div id="sections_components">
      <div v-if="menu_option == 'Tienda'">
        <Pedidos />
      </div>
    </div>

    <div v-if="menu_option == 'Usuarios'" id="btn-agregar" class="row mx-0">
      <a class="color-bg-6 color-4" href="/agregar/usuario/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR USUARIO</a
      >
    </div>
    <div v-if="menu_option == 'Posiciones'" id="btn-agregar" class="row mx-0">
      <a class="color-bg-6 color-4" href="/agregar/tipo/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR POSICIÓN</a
      >
    </div>
    <div v-if="menu_option == 'Equipos'" id="btn-agregar" class="row mx-0">
      <a class="color-bg-6 color-4" href="/agregar/equipo/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR EQUIPO</a
      >
    </div>
    <div v-if="menu_option == 'Jugadores'" id="btn-agregar" class="row mx-0">
      <a class="color-bg-6 color-4" href="/agregar/jugador/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR JUGADOR</a
      >
    </div>

    <div
      v-else-if="menu_option == 'Productos'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/producto/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR PRODUCTO</a
      >
    </div>

    <div
      v-else-if="menu_option == 'Calendario'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/partido/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR PARTIDO</a
      >
    </div>
    <div v-else-if="menu_option == 'Blog'" id="btn-agregar" class="row mx-0 ">
      <a class="color-4 color-bg-6" href="/agregar/entrada/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR ENTRADA</a
      >
    </div>

    <div v-else-if="menu_option == 'Tienda'" id="btn-agregar" class="row mx-0 ">
      <a class="color-4 color-bg-6" href="/agregar/producto/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR PRODUCTO</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Galeria'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/imagen/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR GALERÍA</a
      >
    </div>
    <div v-else-if="menu_option == 'Videos'" id="btn-agregar" class="row mx-0 ">
      <a class="color-4 color-bg-6" href="/agregar/video/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR VIDEO</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Canales'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/canal/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR CANAL</a
      >
    </div>
    <div v-else-if="menu_option == 'Ligas'" id="btn-agregar" class="row mx-0 ">
      <a class="color-4 color-bg-6" href="/agregar/liga/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR LIGA</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Torneos'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/torneo/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR TORNEO</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Perfiles'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/perfil/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR PERFIL</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Patrocinadores'"
      id="btn-agregar-lrg"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/patrocinador/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR PATROCINADOR</a
      >
    </div>
    <div
      v-else-if="menu_option == 'Categorias'"
      id="btn-agregar"
      class="row mx-0 "
    >
      <a class="color-4 color-bg-6" href="/agregar/categoria/">
        <img :src="icon_mas" alt="icon agregar" /> AGREGAR CATEGORÍA</a
      >
    </div>

    <div class="row">
      <!--  <div id="buscador-categoria" v-if="this.menu_option=='Categorías' || this.menu_option=='Productos'">    
          <b-dropdown>
            <template #button-content>
              <div class="row"><span class="drop-text">{{buscador_categoria}}</span></div> 
            </template>

            <div v-for="(item, key) in this.categories" :key="key">
            <b-dropdown-item @click="searchByValue(item.id, item.name, 'category')">
              {{item.name}}
            </b-dropdown-item>
            </div>
          </b-dropdown>
      </div> -->

      <div
        v-if="menu_option != 'Banner' && menu_option != 'Variantes'"
        id="prod-page"
      >
        <b-dropdown>
          <template #button-content>
            <div class="row">
              <span class="drop-text">Items por pagina</span>
            </div>
          </template>
          <b-dropdown-item @click="changeNum(9)">
            9
          </b-dropdown-item>
          <b-dropdown-item @click="changeNum(15)">
            15
          </b-dropdown-item>
          <b-dropdown-item @click="changeNum(21)">
            21
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-if="this.menu_option != 'Variantes'">
        <div v-if="this.menu_option == 'Usuarios'" id="num_items">
          <p class="">
            NO. Items:
            <template v-if="data != 'No se encontraron coincidencias'">
              {{ users.length }}</template
            >
            <template v-else> 0</template>
          </p>
        </div>
        <div v-else id="num_items">
          <p class="">
            NO. Items:
            <template v-if="data != 'No se encontraron coincidencias'">
              {{ data.length }}</template
            >
            <template v-else> 0</template>
          </p>
        </div>
      </div>

      <div v-if="this.menu_option == 'Suscriptores'">
        <CSV :reporte="data" />
      </div>

      <div v-if="this.menu_option != 'Variantes'">
        <div
          id="buscador"
          v-if="
            this.menu_option == 'Categorías' || this.menu_option == 'Productos'
          "
        >
          <div class="row">
            <i class="fas fa-search"></i>
            <div class="form-buscador">
              <input
                v-model="search_value"
                type="search"
                id="input-search"
                class="form-control"
              />
            </div>
            <button
              id="btn-search"
              type="button"
              class="btn_searchimg"
              @click="search(search_value)"
            >
              <img src="../assets/lupa.png" />
            </button>
          </div>
        </div>
        <div
          id="buscador"
          class="searchleft2"
          v-else-if="this.menu_option == 'Suscriptores'"
        >
          <div class="row">
            <i class="fas fa-search"></i>
            <div class="form-buscador">
              <input
                v-model="search_value"
                type="search"
                id="input-search"
                class="form-control"
              />
            </div>
            <button
              id="btn-search"
              type="button"
              class="btn_searchimg"
              @click="search(search_value)"
            >
              <img src="../assets/lupa.png" />
            </button>
          </div>
        </div>

        <div id="buscador" class="searchleft" v-else>
          <div class="row">
            <i class="fas fa-search"></i>
            <div class="form-buscador">
              <input
                v-model="search_value"
                type="search"
                id="input-search"
                class="form-control"
              />
            </div>
            <button
              id="btn-search"
              type="button"
              class="btn_searchimg"
              @click="search(search_value)"
            >
              <img src="../assets/lupa.png" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="menu_option == 'Usuarios'" id="section_usuarios" class="">
      <div id="content-stn">
        <div class="row">
          <div class="Usuarios_Nombre title t1"><p>Nombre</p></div>
          <div class="Usuarios_Apellido title t1"><p>Apellido</p></div>
          <div class="Usuarios_Correo title t1"><p>Correo</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="UData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div id="UData_LastName" class=" data d1 ">
                <p>{{ item.lastname }}</p>
              </div>
              <div id="UData_Email" class=" data d1 ">
                <p>{{ item.email }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/usuario/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-6">Eliminar usuario</p>
                    <p class="modalBody ">
                      ¿Estás seguro que deseas eliminar este usuario?
                    </p>
                  </div>
                  <div class="wrapper center">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two btnright btns "
                      @click="eliminarUsuario(item.id, user)"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data ">
            <p class="msg-coincidencias ">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button @click="prev" id="btn-prev" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-6">{{ currentPage }} - {{ totalPages }}</p>
          <button @click="next" id="btn-next" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="menu_option == 'Blog'">
      <Blog />
    </div>

    <div v-else-if="menu_option == 'Videos'" id="section_usuarios" class="">
      <div id="content-stn">
        <div class="row">
          <div class="Videos_Titulo title t1"><p>Título</p></div>
          <div class="Videos_Fecha title t1"><p>Fecha</p></div>
          <div class="Videos_Url title t1"><p>URL</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="VData_Title" class=" data clmn-one d1 ">
                <p>{{ item.title }}</p>
              </div>
              <div id="VData_DateVideo" class=" data d1 ">
                <p>{{ item.datevideo }}</p>
              </div>
              <div id="VData_Url" class=" data d1 ">
                <p>{{ item.url }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/video/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-6">Eliminar video</p>
                    <p class="modalBody ">
                      ¿Estás seguro que deseas eliminar esta video?
                    </p>
                  </div>
                  <div class="wrapper ">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two btnright btns "
                      @click="eliminarItem(item.id, 'videos')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data ">
            <p class="msg-coincidencias ">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button @click="prev" id="btn-prev" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-6">{{ currentPage }} - {{ totalPages }}</p>
          <button @click="next" id="btn-next" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Calendario'"
      id="section_calendario"
      class=""
    >
      <div id="content-stn">
        <div class="row">
          <div class=" title t1"><p>VS</p></div>
          <div class=" title t5"><p>Fecha</p></div>
          <div class=" title t4"><p>Hora</p></div>
          <div class=" title t1"><p>Tipo de Juego</p></div>
          <div class=" title t1"><p>Estadio</p></div>
          <div class=" title t1"><p>Torneo</p></div>
          <div class=" title t1"><p>Equipo</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div class=" data clmn-one d1 ">
                <p>{{ item.opname }}</p>
              </div>
              <div class=" data d5 ">
                <p>{{ item.descrp }}</p>
              </div>
              <div class=" data  d4 ">
                <p>{{ item.hr }}</p>
              </div>
              <div class=" data  d1 ">
                <p>{{ item.typegame }}</p>
              </div>
              <div class=" data  d1 ">
                <p>{{ item.stadium }}</p>
              </div>
              <div class=" data  d1 ">
                <p>{{ item.name_tournament }}</p>
              </div>
              <div class=" data  d1 ">
                <p>{{ item.name_team }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/partido/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-6">Eliminar calendario</p>
                    <p class="modalBody ">
                      ¿Estás seguro que deseas eliminar este calendario?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two btnright btns "
                      @click="eliminarItem(item.id, 'calendar')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data ">
            <p class="msg-coincidencias ">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button @click="prev" id="btn-prev" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-6">{{ currentPage }} - {{ totalPages }}</p>
          <button @click="next" id="btn-next" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="menu_option == 'Suscriptores'" class="color-bg-five">
      <div id="section_subscriptores">
        <div id="content-stn">
          <div class="row">
            <div class=" title t1"><p>Subscriptor</p></div>

            <div class=" title t2"><p>Borrar</p></div>
          </div>
          <div v-if="data != 'No se encontraron coincidencias'">
            <div v-for="(item, key) in filteredItems" class="items" :key="key">
              <div class="row row-data ">
                <div class=" data clmn-one d1 ">
                  <p>{{ item.email }}</p>
                </div>

                <div class=" data d2 ">
                  <b-btn
                    v-b-modal="'modal-delete' + item.id"
                    variant="outline-secondary"
                    class="eliminar-modal"
                  >
                    <img :src="eliminar" alt="eliminar icon"
                  /></b-btn>
                  <b-modal
                    :id="'modal-delete' + item.id"
                    no-close-on-backdrop
                    centered
                    hide-footer
                    hide-header
                  >
                    <div class="d-block text-center">
                      <img :src="closeIcon" class="icon3 pt-5" /><br />
                      <p class="modalHeader pt-3 color-four">
                        Eliminar subscriptor
                      </p>
                      <p class="modalBody color-one">
                        ¿Estás seguro que deseas eliminar este subscriptor?
                      </p>
                    </div>
                    <div class="wrapper">
                      <b-button class="one  py-3 btnleft btns " @click="wait()"
                        >Cancelar</b-button
                      >
                      <b-button
                        class="two  btnright btns"
                        @click="eliminarItem(item.id, 'suscriptor')"
                        >Confirmar</b-button
                      >
                    </div>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row row-data color-bg-two">
              <p class="msg-coincidencias color-four">{{ data }}</p>
            </div>
          </div>
        </div>

        <div id="navigate-buttons">
          <div class="row">
            <button
              @click="prev"
              id="btn-prev"
              class="btn-navigate color-five color-bg-four"
            >
              <img src="../assets/Arrow-12.png" />
            </button>
            <p class="num-pages color-three">
              {{ currentPage }} - {{ totalPages }}
            </p>
            <button
              @click="next"
              id="btn-next"
              class="btn-navigate color-five color-bg-four"
            >
              <img src="../assets/Arrow-12.png" />
            </button>
          </div>
        </div>
      </div>
      <!--  <img src="../assets/img_mailing.png" style="width: .1vw" alt=""> -->

      <div class="landigScreen">
        <Landing />
      </div>

      <div class="concursoScreen">
        <Concurso />
      </div>
    </div>
    <div v-else-if="menu_option == 'Patrocinadores'">
      <div id="section_galeria" class="color-bg-five">
        <div id="content-stn">
          <div class="row">
            <div class=" title t1"><p>Patrocinadores</p></div>
          </div>
          <div v-if="data != 'No se encontraron coincidencias'">
            <div class="items-galeria row">
              <div
                v-for="(item, key) in filteredItems"
                class="item-galeria"
                :key="key"
              >
                <div class="  ">
                  <div class="">
                    <div class="sponsor">
                      <img
                        class="img-sponsor"
                        :src="url + '/get-sponsor-image/' + item.image"
                        :alt="item.title"
                      />
                    </div>
                    <p class="title-img">{{ item.section }}</p>

                    <p id="Patroc_edit" class="itemgp editar-sp">
                      <a
                        class="editar-icon "
                        :href="'/editar/patrocinador/' + item.id"
                        ><img :src="editar" alt="editar icon"
                      /></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row row-data color-bg-two">
              <p class="msg-coincidencias color-four">{{ data }}</p>
            </div>
          </div>
        </div>
        <div id="navigate-buttons">
          <div class="row">
            <button
              @click="prev"
              id="btn-prev"
              class="btn-navigate color-five color-bg-four"
            >
              <img src="../assets/Arrow-12.png" />
            </button>
            <p class="num-pages color-three">
              {{ currentPage }} - {{ totalPages }}
            </p>
            <button
              @click="next"
              id="btn-next"
              class="btn-navigate color-five color-bg-four"
            >
              <img src="../assets/Arrow-12.png" />
            </button>
          </div>
        </div>
      </div>

      <SponsorFooter />
    </div>

    <div
      v-else-if="menu_option == 'Galeria'"
      id="section_galeria"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class=" title t1"><p>Galeria</p></div>
          <!-- <div class="color-bg-four title t2"><p>Editar</p></div> -->
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div class="galeria_cnt">
            <div class="items-galeria row">
              <div
                v-for="(item, key) in filteredItems"
                class="item-galeria"
                :key="key"
              >
                <div class="item-galeria2">
                  <div class="">
                    <img
                      class="img-glly"
                      :src="url + '/get-gallery-image/' + item.image"
                      :alt="item.title"
                    />
                    <p class="title-img">{{ item.title }}</p>
                    <p>{{ convertDate(item.dateimg) }}</p>
                    <!-- convertDate(item.dateimg) -->
                    <p class="itemgp">
                      <a class="editar-icon" :href="'/editar/imagen/' + item.id"
                        ><img src="../assets/edit-icon.png" alt="editar icon"
                      /></a>
                      <b-btn
                        v-b-modal="'modal-delete' + item.id"
                        variant="outline-secondary"
                        class="eliminar-modal"
                      >
                        <img src="../assets/delete-icon.png" alt="eliminar icon"
                      /></b-btn>
                    </p>

                    <b-modal
                      :id="'modal-delete' + item.id"
                      no-close-on-backdrop
                      centered
                      hide-footer
                      hide-header
                    >
                      <div class="d-block text-center">
                        <img :src="closeIcon" class="icon3 pt-5" /><br />
                        <p class="modalHeader pt-3 color-four">
                          Eliminar imagen
                        </p>
                        <p class="modalBody color-one">
                          ¿Estás seguro que deseas eliminar esta imagen?
                        </p>
                      </div>
                      <div class="wrapper">
                        <b-button
                          class="one  py-3 btnleft btns "
                          @click="wait()"
                          >Cancelar</b-button
                        >
                        <b-button
                          class="two  btnright btns"
                          @click="eliminarItem(item.id, 'gallery')"
                          >Confirmar</b-button
                        >
                      </div>
                    </b-modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Equipos'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Equipos_Equipo title t1"><p>Equipo</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="EData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/equipo/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar equipo</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este equipo?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'teams')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Canales'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Canales_Canal title t1"><p>Canal</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="CANData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/canal/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar canal</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este canal?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'channel')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Ligas'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Ligas_Nombre title t1"><p>Nombre</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="LData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/liga/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar liga</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar esta liga?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'liga')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Perfiles'"
      id="section_perfiles"
      class="color-bg-five Div_Perfiles"
    >
      <div id="content-stn" class="content_colors Content_Colors">
        <div class="row">
          <div class=" title t1" id="Perfil_divblue"><p>Nombre</p></div>
          <div class=" title t4" id="Perfil_divblue"><p>Color 1</p></div>
          <div class=" title t4" id="Perfil_divblue"><p>Color 2</p></div>
          <div class=" title t4" id="Perfil_divblue"><p>Color 3</p></div>
          <div class=" title t4" id="Perfil_divblue"><p>Color 4</p></div>
          <div class=" title t4" id="Perfil_divblue"><p>Color 5</p></div>
          <div class=" title t2" id="Perfil_divred"><p>Editar</p></div>
          <div class=" title t2" id="Perfil_divred"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div class=" data clmn-one d1 " id="Perfil_div2blue">
                <p id="Perfil_nombre">{{ item.name }}</p>
              </div>
              <div class=" data clmn-one d4 " id="Perfil_div2blue">
                <p
                  id="Perfil_col1-4"
                  v-bind:style="{ background: item.color1 }"
                >
                  {{ item.color1 }}
                </p>
              </div>
              <div class=" data clmn-one d4 " id="Perfil_div2blue">
                <p
                  id="Perfil_col1-4"
                  v-bind:style="{ background: item.color2 }"
                >
                  {{ item.color2 }}
                </p>
              </div>
              <div class=" data clmn-one d4 " id="Perfil_div2blue">
                <p
                  id="Perfil_col1-4"
                  v-bind:style="{ background: item.color3 }"
                >
                  {{ item.color3 }}
                </p>
              </div>
              <div class=" data clmn-one d4 " id="Perfil_div2blue">
                <p
                  id="Perfil_col1-4"
                  v-bind:style="{ background: item.color4 }"
                >
                  {{ item.color4 }}
                </p>
              </div>
              <div class=" data clmn-one d4 " id="Perfil_div2blue">
                <p id="Perfil_col5" v-bind:style="{ background: item.color5 }">
                  {{ item.color5 }}
                </p>
              </div>
              <div class=" data d3" id="Perfil_div2red">
                <a :href="'/editar/perfil/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 " id="Perfil_div2red">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar liga</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar esta liga?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'profile')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>

      <div id="section_subscriptores" class="doble_sec">
        <div id="content-stn" class="content_banner">
          <div class="row">
            <div class=" title t1"><p>Modo Banner</p></div>
            <div class=" title t2"><p>Editar</p></div>
          </div>
          <div v-if="banners != 'No se encontraron coincidencias'">
            <div v-for="(item, key) in banners" class="items" :key="key">
              <div class="row row-data ">
                <div class=" data clmn-one d1 ">
                  <p>{{ item.opt }}</p>
                </div>
                <div class=" data d3">
                  <a :href="'/editar/banner/' + item.id"
                    ><img src="../assets/edit_1.png" alt="editar icon"
                  /></a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row row-data color-bg-two">
              <p class="msg-coincidencias color-four">{{ banners }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Categorias'"
      id="section_torneo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Categoria_categoria title t1"><p>Categoría</p></div>
          <div class="Categoria_Padre title t1"><p>Categoría Padre</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="CATData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div id="CATData_Parent" class=" data clmn-one d1 ">
                <p>{{ item.parent_name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/categoria/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">
                      Eliminar categoría
                    </p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar esta categoría?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'category')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Torneos'"
      id="section_usuarios"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Usuarios_Nombre title t1"><p>Torneo</p></div>
          <div class="Usuarios_Apellido title t1"><p>Liga</p></div>
          <div class="Usuarios_Correo title t1"><p>Torneo Principal</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="VData_Title" class=" data  clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div id="VData_DateVideo" class=" data  d1 ">
                <p>{{ item.name_liga }}</p>
              </div>
              <div id="VData_Url" class=" data  d1 ">
                <p>{{ item.slide }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/torneo/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar torneo</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este torneo?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'tournament')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Posiciones'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Posicion_posicion title t1"><p>Posición</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="POData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/tipo/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar tipo</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este tipo?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'type')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Jugadores'"
      id="section_calendario"
      class=""
    >
      <div id="content-stn" class="players">
        <div class="row">
          <div class="Jugadores_Nombre title t1"><p>Nombre</p></div>
          <div class="Jugadores_Nacionalidad title t5"><p>Nacionalidad</p></div>
          <div class="Jugadores_Num title t4"><p>Num</p></div>
          <div class="Jugadores_Equipo title t1"><p>Equipo</p></div>
          <div class="Jugadores_Posicion title t1"><p>Posición</p></div>
          <div class="Jugadores_Editar title t2"><p>Editar</p></div>
          <div class="Jugadores_Borrar title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="JData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div id="JData_Nationality" class=" data d5 ">
                <p>{{ item.nationality }}</p>
              </div>
              <div id="JData_Num" class=" data  d4 ">
                <p>{{ item.num }}</p>
              </div>
              <div id="JData_TeamName" class=" data  d1 ">
                <p>{{ item.team_name }}</p>
              </div>
              <div id="JData_Position" class=" data  d1 ">
                <p>{{ item.type_name }}</p>
              </div>
              <div id="JData_Edit" class=" data d3">
                <a :href="'/editar/jugador/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div id="JData_Deleted" class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-6">Eliminar jugador</p>
                    <p class="modalBody ">
                      ¿Estás seguro que deseas eliminar este jugador?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two btnright btns "
                      @click="eliminarItem(item.id, 'players')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data ">
            <p class="msg-coincidencias ">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button @click="prev" id="btn-prev" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-6">{{ currentPage }} - {{ totalPages }}</p>
          <button @click="next" id="btn-next" class="btn-navigate  ">
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <!--- TIENDA Y SECCIONES RELACIONADAS -->

    <div v-else-if="menu_option == 'Tienda'">
      <Productos />

      <Cupones />
    </div>

    <div v-else-if="menu_option == 'Variantes'">
      <Colores />
      <Tallas />
    </div>

    <!-- --->

    <div
      v-else-if="menu_option == 'Colores'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Colores_Color title t1"><p>Color</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="COData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/color/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar color</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este color?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'color')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div
      v-else-if="menu_option == 'Tallas'"
      id="section_equipo"
      class="color-bg-five"
    >
      <div id="content-stn">
        <div class="row">
          <div class="Tallas_Talla title t1"><p>Talla</p></div>
          <div class=" title t2"><p>Editar</p></div>
          <div class=" title t2"><p>Borrar</p></div>
        </div>
        <div v-if="data != 'No se encontraron coincidencias'">
          <div v-for="(item, key) in filteredItems" class="items" :key="key">
            <div class="row row-data ">
              <div id="TAData_Name" class=" data clmn-one d1 ">
                <p>{{ item.name }}</p>
              </div>
              <div class=" data d3">
                <a :href="'/editar/talla/' + item.id"
                  ><img src="../assets/edit_1.png" alt="editar icon"
                /></a>
              </div>
              <div class=" data d2 ">
                <b-btn
                  v-b-modal="'modal-delete' + item.id"
                  variant="outline-secondary"
                  class="eliminar-modal"
                >
                  <img :src="eliminar" alt="eliminar icon"
                /></b-btn>
                <b-modal
                  :id="'modal-delete' + item.id"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="d-block text-center">
                    <img :src="closeIcon" class="icon3 pt-5" /><br />
                    <p class="modalHeader pt-3 color-four">Eliminar talla</p>
                    <p class="modalBody color-one">
                      ¿Estás seguro que deseas eliminar este talla?
                    </p>
                  </div>
                  <div class="wrapper">
                    <b-button class="one  py-3 btnleft btns " @click="wait()"
                      >Cancelar</b-button
                    >
                    <b-button
                      class="two  btnright btns"
                      @click="eliminarItem(item.id, 'size')"
                      >Confirmar</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row row-data color-bg-two">
            <p class="msg-coincidencias color-four">{{ data }}</p>
          </div>
        </div>
      </div>
      <div id="navigate-buttons">
        <div class="row">
          <button
            @click="prev"
            id="btn-prev"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
          <p class="num-pages color-three">
            {{ currentPage }} - {{ totalPages }}
          </p>
          <button
            @click="next"
            id="btn-next"
            class="btn-navigate color-five color-bg-four"
          >
            <img src="../assets/Arrow-12.png" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="menu_option == 'Cabeceras'">
       <AdminCabeceras/>
    </div>
    <div v-else>
      <h2 class="color-1">Proximamente...</h2>
    </div>

    <!--- Modal de errores  --->
    <b-modal
      id="modal-error"
      ref="modal-error"
      no-close-on-backdrop
      centered
      hide-footer
      hide-header
    >
      <div class="message-modal">
        <img :src="closeIcon" class="icon3 pt-5" /><br />
        <p class="text-modal">{{ msg }}</p>
      </div>
      <div class="">
        <b-button class="btn-modal" @click="wait()">Aceptar</b-button>
        <b-button
          type="button"
          aria-label="Close"
          class="close-modal"
          @click="wait()"
          >×</b-button
        >
      </div>
    </b-modal>
    <!---  --->
  </div>
</template>

<script>
import CSV from "./CSV.vue";
import { mapActions } from "vuex";
import editar from "../assets/editar.png";
import eliminar from "../assets/eliminar.png";
import i_logout from "../assets/iconocerrar.png";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
import icon_mas from "../assets/icon-mas.png";

var relativeTime = require("dayjs/plugin/relativeTime");

import userService from "../services/user.service";

//sections
import Pedidos from "../components/sections/Pedidos.vue";
import Blog from "../components/sections/Blog.vue";
import Productos from "../components/sections/Productos.vue";
import Colores from "../components/sections/Colores.vue";
import Tallas from "../components/sections/Tallas.vue";
import Landing from "../components/sections/Landing.vue";
import Concurso from "../components/sections/Concurso.vue";
import Cupones from "../components/sections/Cupones.vue";
import SponsorFooter from "../components/sections/sponsor_footer.vue";
import AdminCabeceras from "../components/sections/AdminCabeceras.vue";
export default {
  name: "Section",
  components: {
    CSV,
    Pedidos,
    Blog,
    Productos,
    Colores,
    Tallas,
    Landing,
    Concurso,
    Cupones,
    SponsorFooter,
    AdminCabeceras
  },
  data() {
    return {
      editar: editar,
      eliminar: eliminar,
      icon_mas: icon_mas,
      closeIcon: i_logout,
      currentPage: 1,
      itemsPerPage: 10,
      search_value: "",
      upd_search_v: "",

      buscador_categoria: "",
      msg: "",
    };
  },
  updated() {
    if (this.upd_search_v != this.search_value) {
      this.upd_search_v = this.search_value;
      let option = this.menu_option;
      let value = this.search_value;
      this.currentPage = 1;
      let payload = {
        option: option,
        search: value,
      };
      if (value != "") {
        this.$store.dispatch("main/search", payload);
      } else {
        let option = this.menu_option;
        this.$store.dispatch("main/setHistoryOption", option);
        this.getAllInfo(option);
      }
    }
  },
  created() {
    if (this.getNav() != false) {
      let option = this.getNav().option;

      this.$store.dispatch("main/setHistoryOption", option);
      this.getAllInfo(option);

      this.delItem();
    } else {
      let option = this.menu_option;

      let user = this.identity();

      if (option == "Calendario") {
        user == "editor" ? (option = "Tienda") : option;
        user == "comunicacion" ? (option = "Blog") : option;
        user == "multimedia" ? (option = "Videos") : option;
      }

      this.$store.dispatch("main/setHistoryOption", option);
      this.getAllInfo(option);
    }

    this.getUsers("usuarios");
    this.getBanners("Banner");

    this.getCategories("Categorías");
  },
  computed: {
    users() {
      return this.$store.getters["users/data"];
    },
    menu_option() {
      return this.$store.getters["main/getHistoryOption"];
    },
    historyOption() {
      return this.$store.getters["main/getHistoryOption"];
    },
    historyIndex() {
      return this.$store.getters["main/getHistoryIndex"];
    },
    data() {
      this.buscador_categoria = this.$store.getters["main/msg"];

      let option = this.menu_option;

      if (option == "Categorias") {
        //filtrar categorias para evitar mostrar la categoria inicio
        let data = [];

        let result = this.$store.getters["main/data"];

        for (var i = 0; i < result.length; i++) {
          if (result[i].name != "INICIO" && result[i].id != 1) {
            data.push(result[i]);
          }
        }
        return data;
      } else {
        return this.$store.getters["main/data"];
      }
    },
    colores() {
      return this.$store.getters["color/data"];
    },
    Landing() {
      return this.$store.getters["landing/data"];
    },
    Concurso() {
      return this.$store.getters["concurso/data"];
    },
    tallas() {
      return this.$store.getters["size/data"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
    banners() {
      return this.$store.getters["banner/data"];
    },
    cabeceras() {
      return this.$store.getters["cabeceras/data"];
    },

    categories() {
      let categories = this.$store.getters["categories/data"];
      let result = [];
      result.push({ id: "Ver Todos", name: "Ver Todos" });
      for (var i = 0; i < categories.length; i++) {
        result.push(categories[i]);
      }
      return result;
    },

    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    allItems() {
      if (this.menu_option == "Usuarios") {
        return Object.keys(this.users).map((pid) => this.users[pid]);
      } else {
        return Object.keys(this.data).map((pid) => this.data[pid]);
      }
    },
    filteredItems() {
      return this.allItems.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
    },
    totalPages() {
      let total = Math.ceil(this.allItems.length / this.itemsPerPage);
      if (total < this.currentPage) {
        this.currentPage = 1;
      }
      return total;
    },
  },
  methods: {
    ...mapActions("main", ["setHistoryOption"]),
    ...mapActions("main", ["setHistoryIndex"]),
    ...mapActions("users", ["getUsers"]),
    ...mapActions("main", ["getAllInfo"]),
    ...mapActions("categories", ["getCategories"]),
    ...mapActions("main", ["setHistoryOption"]),
    ...mapActions("color", ["getColors"]),
    ...mapActions("banner", ["getBanners"]),
    ...mapActions("size", ["getSizes"]),
    eliminarUsuario: async function(user_id) {
      // this.$store.dispatch("main/deleteItem", {option: 'user', id:user_id});
      let result = await this.$store.dispatch("main/deleteItem", {
        option: "user",
        id: user_id,
      });

      if (user_id == this.user.sub) {
        this.logout();
      } else {
        if (result.status == "error") {
          this.msg = result.message;
          this.showModalError();
        } else {
          this.msg = "";
          this.wait();
        }
      }
    },
    eliminarItem: async function(id, option) {
      //console.log(id + ' - '+ option)
      let result = await this.$store.dispatch("main/deleteItem", {
        option: option,
        id: id,
      });

      if (result.status == "error") {
        this.msg = result.message;
        this.showModalError();
      } else {
        this.msg = "";
        this.wait();
      }
    },
    wait: function() {
      setTimeout(() => this.$router.go(), 200);
    },
    logout: function() {
      this.$router.push({ name: "Login" });
      this.$store.dispatch("admin/logout");
    },
    next() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prev() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    search: function(value) {
      let option = this.menu_option;

      this.currentPage = 1;
      let payload = {
        option: option,
        search: value,
      };
      if (value != "") {
        this.$store.dispatch("main/search", payload);
      } else {
        let option = this.menu_option;
        this.$store.dispatch("main/setHistoryOption", option);
        this.getAllInfo(option);
      }
    },
    searchByValue: function(id, name, value) {
      //console.log(id+'  -  '+name+'  -  '+ value)
      /*let option =  this.menu_option;
           this.currentPage=1;
           if(name=='Ver Todos'){
             
              this.$store.dispatch("main/setHistoryOption", option);
              this.getAllInfo(option)
           }else{
              let payload ={
                id:id,
                name: name,
                option:option
              }
              this.$store.dispatch("main/searchByCategory", payload);
           }*/
    },
    changeNum: function(num) {
      this.itemsPerPage = num;
    },
    showModalError() {
      this.$refs["modal-error"].show();
    },
    convertDate(date) {
      dayjs.extend(relativeTime);
      dayjs.locale("es");

      let dateSplit = date.split("-");
      let yy = dateSplit[0];
      let mm = dateSplit[1];
      let dd = dateSplit[2];
      date = dd + "/" + mm + "/" + yy;
      //dayjs(date).fromNow()
      return date;
    },
    getNav: function() {
      let options = localStorage.getItem("nav");
      if (options == null || options == undefined || options == "") {
        return false;
      } else {
        return (options = JSON.parse(options));
      }
    },
    delItem: function() {
      localStorage.removeItem("nav");
    },
    identity: function() {
      //validar que no entren a la ruta de editar usuarios especificos

      let user = "";
      try {
        let token = userService.getToken();
        user = userService.decode(token);
        if (user.role) {
          user = user.role;
          user = user.toLowerCase();
        } else {
          user = "error";
        }
      } catch (error) {
        user = "error";
      }

      return user;
    },
  },
};
</script>

<style scoped></style>
