<template>
    <div id="Pedidos">
        <h1 id="title">PEDIDOS</h1>
        <div class="dflx">
    
                <div  id="prod-page">    
                    <b-dropdown>
                        <template #button-content>
                        <div class="row"><span class="drop-text">Items por pagina</span></div> 
                        </template>
                        <b-dropdown-item @click="changeNum(2)">
                            2
                        </b-dropdown-item>
                        <b-dropdown-item @click="changeNum(10)">
                            10
                        </b-dropdown-item>
                        <b-dropdown-item @click="changeNum(20)">
                          20
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
    
                 <div  id="num_items">    
                        <p class="">NO. Pedidos:  <template v-if="data != 'No se encontraron coincidencias' " > {{data.length}}</template>  <template v-else> 0</template> </p>
                 </div>

                 <div id="buscador" class="fixsearch">
                    <div class="dflx">
                    
                    <i class="fas fa-search"></i>
                    <div class="form-buscador">
                        <input v-model="search_value" type="search" id="input-search" class="form-control"  />
                    </div>
                    <button id="btn-search" type="button" class="btn_searchimg" @click="search(search_value)">
                        <img src="../../assets/lupa.png">
                    </button>
                    </div>
                </div>
                
                
        </div>

        <p class="textsearch"> * Puedes buscar por:  numero, status, teléfono, cliente, email.</p>

        <div class="box_content">

            <div class="comp_tbl_sec">
            <div class="header_tbl_sec">
                <p class="htbl1">Numero</p>
                <p class="htbl2">Fecha</p>
                <p class="htbl3"> Cliente</p>
                <p class="htbl4"> Email</p>
                <p class="htbl5">Estatus</p>
                <p class="htbl6">Total</p>
                 <p class="htbl8">Facturar</p>
                <p class="htbl7">Editar</p>
            </div>
            <div v-if="data != 'No se encontraron coincidencias'" class="content_tbl_sec">
                <div v-for="(item, key) in filteredItems" :key="key" class="item_cont_tbl">
                    <p class="itbl1">{{item.purchase_num}}</p>
                    <p class="itbl2">{{item.purchase_date}}</p>
                    <p class="itbl3"> {{item.name + ' ' + item.lastname}}</p>
                    <p class="itbl4"> {{item.email}}</p>
                    <p class="itbl5">{{item.status}}</p>
                    <p class="itbl6">{{item.total}}</p>
                     <p class="itbl8">   <template v-if="item.billing !='No se encontraron coincidencias'"> SI </template>   <template v-else> NO </template> </p>
                    <p class="itbl7" @click="editar(item.id)"><img src="../../assets/edit_1.png" alt="editar icon"></p>
                </div>

                 <div id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                        <p class="num-pages color-6">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                     </div>
                </div>
            </div>
            <div v-else class="content_tbl_sec">
                <div class="item_cont_tbl">
                    <p class="itblNA">Sin pedidos registrados...</p>
                </div>
            </div>

        </div>
        </div>
        
        <h1 id="title">PRODUCTOS</h1>

       <!--  {{data}} -->
    </div>
</template>
<script>

import {mapActions} from 'vuex';
export default {
    name:'Pedidos',
    data() {
        return {
            status:'',
            msg:'',

            currentPage: 1,
            itemsPerPage: 2,


            search_value:"",
            upd_search_v:"",
        }
    },
    updated(){
    if(this.upd_search_v != this.search_value){
      this.upd_search_v = this.search_value
      let value = this.search_value
         let option = 'pedidos';
           
            this.currentPage=1;
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("purchase/search", payload);
            }else{
              this.getAll('pedidos')
            }
    }
  },
    created(){
        this.getAll('pedidos')
    },
    computed:{
        data() {
           return   this.$store.getters["purchase/data"]; //'No se encontraron coincidencias'//
        }, 
        

        //Paginacion
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
        }
    },
    methods:{
        ...mapActions('purchase', ['getAll']),
        editar: function(id){
            this.$router.push("/editar/pedido/"+id).catch(err => {});
           
        },
         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },









         search: function(value){
            let option = 'pedidos';
           
            this.currentPage=1;
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("purchase/search", payload);
            }else{
              this.getAll('pedidos')
            }
        },

        // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
        changeNum: function(num){
          this.itemsPerPage= num
        },
    }

}//m7c-aJu9fl1q_6d9hv/pedidos
</script>
<style scoped>
    .dflx{
        display: flex;
    }
    #Pedidos{
        margin-bottom: 10vw;
        width: 77vw !important;
        margin-left: 1.9vw;

    }

    h1#title{
        color: var(--color-7);
        font-weight: 900;
        font-size: 2vw;
       
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAAAMCAYAAAC3Hkm2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA4SURBVHgB7dFBFQAQAEAx3EXQP4J49Phvq7B593mDnDVIEhslNkpslNgosVFio8RGiY0SGyU26gOwDgH4MhHt5QAAAABJRU5ErkJggg==);
        background-size: 77vw;
        background-repeat: no-repeat;
        background-position-x: 0vw;
        background-position-y: 2.2vw;
        margin-bottom: 2.5vw;

    }

    .box_content{
        width: 77vw !important;
        border: 0.06944444444444445vw solid var(--color-8);
        border-radius: 2.8vw;
        margin-bottom: 5vw;
    }
    .comp_tbl_sec{
        margin-top: 2.65625VW;
    }
    .header_tbl_sec{
        display: flex;
        background: var(--color-1);
        margin-left: -0.007vw;
        height: 4.9VW !important;;
        width: 76.9vw;
    }.header_tbl_sec p{
       height: 4.9VW ;
       font-size: 1.25VW;
       font-style: normal;
       font-weight: 600;
       color: #FFFFFF;
       padding-top: 1.7vw;
       text-align: center;
      
       overflow-x: auto;
    }.htbl1,.itbl1{
        width: 14vw;
    }.htbl2,.itbl2{
        width: 10vw;
    }.htbl3,.itbl3{
        width: 10vw;
    }.htbl4,.itbl4{
        width: 13vw;
    }.htbl5,.itbl5{
        width: 10vw;
    }.htbl6,.itbl6{
        width: 8vw;
    }.htbl8,.itbl8{
        width: 5.5vw;
    }.htbl7{
        background: var(--color-2);
        width: 6.44vw;
        
    }

    .content_tbl_sec{
        margin-bottom: 1vw;
    }
  
    .itbl7{
        width: 6.44vw; 
        cursor: pointer;
    }.itbl7 img{
        width: 1.4583333333333333VW;
        height: 1.4583333333333333VW;
    }

    .item_cont_tbl{
        display: flex;
        height: 2.9VW ;
        margin-bottom: .5vw;
       
    }.item_cont_tbl p{
        padding-top: .8vw;
        height: 2.9VW ;
        overflow: auto;
        text-align: center;
         font-size: .9vw;
        color: var(--color-8);
        font-weight: 800;
    }

    .itblNA{
        width: 77vw;
        text-align: center;
    }

    .fixsearch{
        margin-top: 2.2vw !important;
        margin-left: 30vw !important;
    }
    p.textsearch{
        text-align: right;
        width: 77vw;
        padding-top: .3vw;
        padding-right: 4vw;
        color: #797979;
        font-size: .6vw;
    }


    /** estilos scroll bar */
    .item_cont_tbl p::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .item_cont_tbl p::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-button:increment,.item_cont_tbl p::-webkit-scrollbar-button {
        display: none;
    } 

    .item_cont_tbl p::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-thumb {
        background-color: #797979;
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .item_cont_tbl p::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }
</style>