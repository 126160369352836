<template >
    <div id="Colores">
       
         <!--<h1 id="title">Concurso Actual</h1>--->


        <div class="dflx">
    
                <div  id="prod-page">    
                    <b-dropdown>
                        <template #button-content>
                        <div class="row"><span class="drop-text">Items por pagina</span></div> 
                        </template>
                        <b-dropdown-item @click="changeNum(2)">
                            2
                        </b-dropdown-item>
                        <b-dropdown-item @click="changeNum(10)">
                            10
                        </b-dropdown-item>
                        <b-dropdown-item @click="changeNum(20)">
                          20
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
    
                 <div  id="num_items">    
                        <p class="">NO. Participantes:  <template v-if="data != 'No se encontraron coincidencias' " > {{data.length}}</template>  <template v-else> 0</template> </p>
                 </div>

                 <div id="buscador" class="fixsearch">
                    <div class="dflx">
                    
                    <i class="fas fa-search"></i>
                    <div class="form-buscador">
                        <input v-model="search_value" type="search" id="input-search" class="form-control"  />
                    </div>
                    <button id="btn-search" type="button" class="btn_searchimg" @click="search(search_value)">
                        <img src="../../assets/lupa.png">
                    </button>
                    </div>
                </div>
                
                
        </div>

        <p class="textsearch"> * Puedes buscar por:  nombre.</p>

        <div class="box_content">

            <div class="comp_tbl_sec">
            <div class="header_tbl_sec">
                <p class="htbl0">Nombre</p>
                <p class="htbl1">Telefono</p>
                <p class="htbl2">Email</p>
                <p class="htbl1">Concurso</p>
              
                <p class="htbl7">Eliminar</p>
            </div>
            <div v-if="data != 'No se encontraron coincidencias'" class="content_tbl_sec">
                <div v-for="(item, key) in filteredItems" :key="key" class="item_cont_tbl">
                    <p class="itbl0">{{item.name}}</p>
                    <p class="itbl1">{{item.phone}}</p>
                    <p class="itbl2">{{item.email}}</p>
                    <p class="itbl1">{{item.concurso}}</p>
                   
                  
                    <p class="itbl7" @click="eliminarItem(item.id, 'concurso')"><img src="../../assets/eliminar.png" alt="editar icon"></p>
                    
                </div>

                

                 <div id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                        <p class="num-pages color-6">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                     </div>
                </div>
            </div>
            <div v-else class="content_tbl_sec">
                <div class="item_cont_tbl">
                    <p class="itblNA">Sin participantes registrados...</p>
                </div>
            </div>

        </div>
        </div>
        
      

       <!--- Modal de errores  --->
        <b-modal
            id="modal-error"
            ref="modal-error"
            no-close-on-backdrop
            centered
            hide-footer
            hide-header
        >
            <div class="message-modal">
            <img :src="closeIcon" class="icon3 pt-5"><br>
            <p class="text-modal">{{msg}}</p>
            </div>
            <div class="">
            <b-button class="btn-modal" @click="wait()"
                >Aceptar</b-button
            >
            <b-button
                type="button"
                aria-label="Close"
                class="close-modal"
                @click="wait()"
                >×</b-button
            >
            </div>
        </b-modal>
  <!---  
         <h1 id="title">Participantes</h1>--->
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import editar from '../../assets/editar.png';
import eliminar from '../../assets/eliminar.png';
import i_logout from '../../assets/iconocerrar.png';
import icon_mas from '../../assets/icon-mas.png';

export default {
     name:'Concurso',
    data() {
        return {
          editar: editar,
          eliminar: eliminar,
          icon_mas: icon_mas,
          closeIcon: i_logout, 
          currentPage: 1,
          itemsPerPage:   10,
          search_value:"",
          upd_search_v:"",
          buscador_categoria:"",
          msg:"",
          status:""
        }
    },
      updated(){
    if(this.upd_search_v != this.search_value){
      this.upd_search_v = this.search_value
      let value = this.search_value
        let option = 'concurso';
           
            this.currentPage=1;
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("concurso/search", payload);
            }else{
              this.getConcurso('concurso')
            }
    }
  },
    created(){
            this.getConcurso('concurso')
    },
    computed:{

        data() {
           return this.$store.getters["concurso/data"];
        }, 
        

        //Paginacion
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
    methods:{
         ...mapActions('concurso', ['getConcurso']),
        editarView: function(id){
            this.$router.push("/editar/concurso/"+id).catch(err => {});
           
        },
         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        showModalError() {
            this.$refs["modal-error"].show();
         },



        eliminarItem: async function(id, option){
         //console.log(id + ' - '+ option)
        let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
        
         if(result.status=='error'){
           this.msg = result.message;
           this.showModalError();
         }else{
           this.msg='';
           this.wait()
         }
         
        },



        search: function(value){
            let option = 'concurso';
           
            this.currentPage=1;
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("concurso/search", payload);
            }else{
              this.getConcurso('concurso')
            }
        },

         changeNum: function(num){
          this.itemsPerPage= num
        },
    

        // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
        
    }
}
</script>
<style scoped>
     .dflx{
        display: flex;
    }
    #Colores{
        margin-bottom: 10vw;
        width: 77vw !important;
        margin-left: 1.9vw;
    }
    #Colores #btn-agregar{
            margin-top: 0vw !important;
    }

    h1#title{
        color: var(--color-7);
        font-weight: 900;
        font-size: 2vw;
       
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAAAMCAYAAAC3Hkm2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA4SURBVHgB7dFBFQAQAEAx3EXQP4J49Phvq7B593mDnDVIEhslNkpslNgosVFio8RGiY0SGyU26gOwDgH4MhHt5QAAAABJRU5ErkJggg==);
        background-size: 77vw;
        background-repeat: no-repeat;
        background-position-x: 0vw;
        background-position-y: 2.2vw;
        margin-bottom: 2.5vw;

    }

    .newPL a{
        padding-left: 0.4vw !important;
    }

    .box_content{
        width: 77vw !important;
        border: 0.06944444444444445vw solid var(--color-8);
        border-radius: 2.8vw;
        margin-bottom: 5vw;
    }
    .comp_tbl_sec{
        margin-top: 2.65625VW;
    }
    .header_tbl_sec{
        display: flex;
        background: var(--color-1);
        margin-left: -0.007vw;
        height: 4.9VW !important;;
        width: 76.9vw;
    }.header_tbl_sec p{
       height: 4.9VW ;
       font-size: 1.25VW;
       font-style: normal;
       font-weight: 600;
       color: #FFFFFF;
       padding-top: 1.7vw;
       text-align: center;
      
       overflow-x: auto;
    }

    .htbl0,.itbl0{
        width: 21vw;
    }

    .htbl2,.itbl2{
        width: 22vw;
    }
    
    .htbl1,.itbl1{
        width: 14vw;

    }.htbl7{
        background: var(--color-2);
        width: 6.44vw;
        
    }

    .content_tbl_sec{
        margin-bottom: 1vw;
    }
  
    .itbl7{
        width: 6.44vw; 
        cursor: pointer;
    }.itbl7 img{
        width: 1.4583333333333333VW;
        height: 1.4583333333333333VW;
    }.itbl7 button.eliminar-modal {
        margin-top: .5vw !important;
    }

    .item_cont_tbl{
        display: flex;
        height: 2.9VW ;
        margin-bottom: .5vw;
       
    }.item_cont_tbl p{
        padding-top: .8vw;
        height: 2.9VW ;
        overflow: auto;
        text-align: center;
         font-size: .9vw;
        color: var(--color-8);
        font-weight: 800;
    }

    .itblNA{
        width: 77vw;
        text-align: center;
    }

    .fixsearch{
        margin-top: 2.2vw !important;
        margin-left: 30vw !important;
    }
    p.textsearch{
        text-align: right;
        width: 77vw;
        padding-top: .3vw;
        padding-right: 4vw;
        color: #797979;
        font-size: .6vw;
    }

    p.item-cat{
        padding: 0vw !important;
        margin: 0vw !important;
    }


    /** estilos scroll bar */
    .item_cont_tbl p::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .item_cont_tbl p::-webkit-scrollbar:vertical {
        width:0.5645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-button:increment,.item_cont_tbl p::-webkit-scrollbar-button {
        display: none;
    } 

    .item_cont_tbl p::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-thumb {
        background-color: #797979;
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .item_cont_tbl p::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }
</style>