<template >
     <div id="Productos">
       

        <div class="box_content">

            <div class="comp_tbl_sec">
            <div class="header_tbl_sec">
                <p class="htbl1">Producto</p>
                <p class="htbl2">Precio</p>
                <p class="htbl3">Stock</p>
                <p class="htbl4">Código</p>
                <p class="htbl5">Categoría</p>
                <p class="htbl7">Editar</p>
                <p class="htbl7">Eliminar</p>
            </div>
            <div v-if="data != 'No se encontraron coincidencias'" class="content_tbl_sec">
                <div v-for="(item, key) in filteredItems" :key="key" class="item_cont_tbl">
                    <p class="itbl1">{{item.name}}</p>
                    <p class="itbl2">{{item.price}}</p>
                    <p class="itbl3"> {{item.total_stock}}</p>
                    <p class="itbl4"> {{item.code}} </p>
                   <p class="itbl5"> {{item.category_name}} </p>
                    <p class="itbl7" @click="editarView(item.id)"><img src="../../assets/edit_1.png" alt="editar icon"></p>
                     <div class="itbl7 ">

                                    <b-btn v-b-modal="'modal-delete'+item.id" variant="outline-secondary" class="eliminar-modal"> <img :src="eliminar" alt="eliminar icon"></b-btn>
                                      <b-modal   :id="'modal-delete'+item.id"   no-close-on-backdrop centered hide-footer hide-header>
                                          <div class="d-block text-center">
                                              <img :src="closeIcon" class="icon3 pt-5"><br>
                                              <p class="modalHeader pt-3 color-6">Eliminar producto</p>
                                              <p class="modalBody ">¿Estás seguro que deseas eliminar este producto?</p>
                                          </div>
                                          <div class="wrapper">
                                              <b-button class="one  py-3 btnleft btns " @click="wait()">Cancelar</b-button>
                                              <b-button class="two btnright btns " @click="eliminarItem(item.id, 'product')">Confirmar</b-button>
                                          </div>
                                      </b-modal>

                                  </div>
                </div>

                 <div id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                        <p class="num-pages color-6">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  "><img src="../../assets/Arrow-12.png"></button>
                     </div>
                </div>
            </div>
            <div v-else class="content_tbl_sec">
                <div class="item_cont_tbl">
                    <p class="itblNA">Sin productos registrados...</p>
                </div>
            </div>

        </div>
        </div>
        
      

       <!--- Modal de errores  --->
        <b-modal
            id="modal-error"
            ref="modal-error"
            no-close-on-backdrop
            centered
            hide-footer
            hide-header
        >
            <div class="message-modal">
            <img :src="closeIcon" class="icon3 pt-5"><br>
            <p class="text-modal">{{msg}}</p>
            </div>
            <div class="">
            <b-button class="btn-modal" @click="wait()"
                >Aceptar</b-button
            >
            <b-button
                type="button"
                aria-label="Close"
                class="close-modal"
                @click="wait()"
                >×</b-button
            >
            </div>
        </b-modal>
  <!---  --->
      <h1 id="title">CUPONES</h1>
    </div>
</template>



<script>

import {mapActions} from 'vuex';
import editar from '../../assets/editar.png';
import eliminar from '../../assets/eliminar.png';
import i_logout from '../../assets/iconocerrar.png';
import icon_mas from '../../assets/icon-mas.png';

export default {
    name:'Productos',
    data() {
        return {
          editar: editar,
          eliminar: eliminar,
          icon_mas: icon_mas,
          closeIcon: i_logout, 
         
         
          currentPage: 1,
          itemsPerPage:   10,
          search_value:"",
          buscador_categoria:"",
          msg:"",
          status:""
        }
    },
    created(){
            this.getAllInfo('tienda')
    },
    computed:{

        data() {
           return this.$store.getters["main/data"];
        }, 
        

        //Paginacion
        allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
    methods:{
         ...mapActions('main', ['getAllInfo']),
        editarView: function(id){
            this.$router.push("/editar/producto/"+id).catch(err => {});
           
        },
         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        eliminarItem: async function(id, option){
         //console.log(id + ' - '+ option)
        let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
        
         if(result.status=='error'){
           this.msg = result.message;
           this.showModalError();
         }else{
           this.msg='';
           this.wait()
         }
         
        },
        showModalError() {
            this.$refs["modal-error"].show();
         },







        // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
        
    }
}
</script>
<style scoped>
    .dflx{
        display: flex;
    }
    #Productos{
        margin-bottom: 10vw;
        width: 77vw !important;
        margin-left: 1.9vw;
    margin-top: 3.0729166666666665vw;
    }

    h1#title{
        color: var(--color-7);
        font-weight: 900;
        font-size: 2vw;
       
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAAAMCAYAAAC3Hkm2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA4SURBVHgB7dFBFQAQAEAx3EXQP4J49Phvq7B593mDnDVIEhslNkpslNgosVFio8RGiY0SGyU26gOwDgH4MhHt5QAAAABJRU5ErkJggg==);
        background-size: 77vw;
        background-repeat: no-repeat;
        background-position-x: 0vw;
        background-position-y: 2.2vw;
        margin-bottom: 2.5vw;

    }

    .box_content{
        width: 77vw !important;
        border: 0.06944444444444445vw solid var(--color-8);
        border-radius: 2.8vw;
        margin-bottom: 5vw;
    }
    .comp_tbl_sec{
        margin-top: 2.65625VW;
    }
    .header_tbl_sec{
        display: flex;
        background: var(--color-1);
        margin-left: -0.007vw;
        height: 4.9VW !important;;
        width: 76.9vw;
    }.header_tbl_sec p{
       height: 4.9VW ;
       font-size: 1.25VW;
       font-style: normal;
       font-weight: 600;
       color: #FFFFFF;
       padding-top: 1.7vw;
       text-align: center;
      
       overflow-x: auto;
    }.htbl1,.itbl1{
        width: 20vw;
    }.htbl2,.itbl2{
        width: 10vw;
    }.htbl3,.itbl3{
        width: 10vw;
    }.htbl4,.itbl4{
        width: 13vw;
    }.htbl5,.itbl5{
        width: 13vw;
    }.htbl7{
        background: var(--color-2);
        width: 6.44vw;
        
    }

    .content_tbl_sec{
        margin-bottom: 1vw;
    }
  
    .itbl7{
        width: 6.44vw; 
        cursor: pointer;
    }.itbl7 img{
        width: 1.4583333333333333VW;
        height: 1.4583333333333333VW;
    }.itbl7 button.eliminar-modal {
        margin-top: .5vw !important;
    }

    .item_cont_tbl{
        display: flex;
        height: 2.9VW ;
        margin-bottom: .5vw;
       
    }.item_cont_tbl p{
        padding-top: .8vw;
        height: 2.9VW ;
        overflow: auto;
        text-align: center;
         font-size: .9vw;
        color: var(--color-8);
        font-weight: 800;
    }

    .itblNA{
        width: 77vw;
        text-align: center;
    }

    .fixsearch{
        margin-top: 2.2vw !important;
        margin-left: 30vw !important;
    }
    p.textsearch{
        text-align: right;
        width: 77vw;
        padding-top: .3vw;
        padding-right: 4vw;
        color: #797979;
        font-size: .6vw;
    }

    p.item-cat{
        padding: 0vw !important;
        margin: 0vw !important;
    }


    /** estilos scroll bar */
    .item_cont_tbl p::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .item_cont_tbl p::-webkit-scrollbar:vertical {
        width:0.5645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-button:increment,.item_cont_tbl p::-webkit-scrollbar-button {
        display: none;
    } 

    .item_cont_tbl p::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .item_cont_tbl p::-webkit-scrollbar-thumb {
        background-color: #797979;
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .item_cont_tbl p::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }
</style>