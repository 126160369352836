<template>
    <div>
        <div class="row mx-0">
            
            <div id="header" class="col-sm-12 col-md-12  color-bg-4">
             
                <Header
                :name="user.fullname"
                /> 
            </div>
            <div class="col-sm-12 col-md-12">
              
                
                <div id="content" class="row  color-bg-4">
                    <SideMenu/>
                    <Section/>
                </div>

              
            </div>
            
        </div>
        
    </div>
</template>


<script>

import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import i_logout from '../assets/iconocerrar.png';
import Section from '../components/Section';
import { mapActions } from "vuex";
export default {
    name: 'Dashboard',
    components: {
        Header,
        SideMenu,
        Section,
    },
    data() {
        return {
            closeIcon: i_logout,
        }
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    }, 
    created() {
       
    },
    updated(){
     
    },
    computed: {
         historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
         historyIndex(){
            return this.$store.getters["main/getHistoryIndex"]
        },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    },
    methods: {
           ...mapActions('main', ['setHistoryOption']),
         ...mapActions('main', ['setHistoryIndex']),
        logout: function(){
            this.$router.push({ name: "Login"});
            this.$store.dispatch("admin/logout");
        }
    },

}
</script>
