<template>
<div>
       <b-button v-if="downloadText =='Descargar .CSV'"
     class="downloadbtn mr-1 mrcsv"
     @click="download(filename, reporte)">
        {{downloadText}} <img v-if="!isDownloaded" :src="icon" class="icon mb-1 mr-1" /><img v-else :src="check" class="icon mb-1 mr-1" /> 

      </b-button> 
       <b-button v-else
     class="downloadbtn mr-1"
     @click="download(filename, reporte)">
        {{downloadText}} <img v-if="!isDownloaded" :src="icon" class="icon mb-1 mr-1" /><img v-else :src="check" class="icon mb-1 mr-1" /> 

      </b-button> 
</div>
   
</template>

<script>
import download_icon from '../assets/icono_descargar.png';
import check from '../assets/icono_check.png';

export default {
    name: 'CSV',
    props: {
        reporte:{
            required: true
        },
    },
    data() {
        return {
            icon: download_icon, 
            filename: 'CIMARRONES SUSCRIPTORES.csv',
            check: check,         
        }
    },
    computed: {
        isDownloaded() {
            return this.$store.getters['admin/downloadFlag'];
        },
        downloadText() {
            return this.$store.getters['admin/downloadText'];
        },
        user() {
        return this.$store.getters['userService/getIdentity'];
        },
    },
    methods:{
        download: function(filename, rows) { 
            this.$store.commit('admin/DOWNLOADING', 'Descargando...');                
            let newArr = [];            
            rows.forEach(item => {
                let tempArr = [];
                tempArr.push(item.email);    
                newArr.push(tempArr);
            });

            let csvFile = '';
            let arrlen = newArr.length;
            for (let i = 0; i < arrlen; i++) {        
                csvFile += this.processRow(newArr[i]);
            }
            this.blob(filename, csvFile)
        },
        processRow: function (row) {
            let finalValue = '';
            let rowlen = row.length;

            for (let j = 0; j < rowlen; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0){ result = '"' + result + '"'; }
                if (j > 0){ finalValue += ',';}

                finalValue += result;
            }
            return finalValue + '\n';
        },
        blob: function (filename, csvFile){
            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                let link = document.createElement("a");
                if (link.download !== undefined) {
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.click();
                }
            }
            this.$store.commit('admin/DOWNLOAD_SUCCESS', 'Archivo descargado'); 
        }
    }
}
</script>

